<style lang="scss">
.newsflash-inner {
  transition: $transition-std;
  border-bottom: 1px solid #eee;
  padding: 25px 15px;
  //margin-bottom:10px;
  &:last-child {
    border-bottom: 0;
  }
  .btn {
    border: 1px solid #eee;
    margin-right: 5px;
    svg {
      width: 20px;
      height: 20px;
      fill: #888;
    }
    &:hover {
      svg {
        fill: #333;
      }
    }
  }
  .btn-edit {
    margin-bottom: 5px;
  }
  .title {
    //border:1px solid blue;
    font-size: 120%;
    //font-family:"Helvetica Neue LT W05 65 Medium";
    font-family: 'Call Two OT W03 Regular';
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  .date {
    font-size: 90%;
    margin-top: 5px;
  }

  .btn-move {
    border: 1px solid #eee;
    border-radius: 0.25rem;
    cursor: pointer;
    padding: 5px;
    span {
      display: block;
      font-size: 80%;
      text-align: center;
      letter-spacing: 1px;
      display: none;
    }
    svg {
      display: block;
      margin: 5px auto 5px auto;
      fill: #888;
    }
    &:hover {
      background: #fff;
      svg {
        fill: #000;
      }
    }
  }

  .link {
    text-align: center;
    a {
      display: block;
      transition: $transition-std;
      svg {
        fill: #888;
        width: 30px;
        height: 30px;
      }
    }
  }
  &:hover {
    background: #f1f1f1;
    a {
      padding-left: 25px;
      svg {
        fill: #000;
      }
    }
  }
}
</style>

<template>
  <div class="newsflash-inner">
    <div class="row">
      <div class="col-lg-1 justify-content-center align-self-center" v-hasperm="'newsflash.edit'">
        <div class="btn btn-edit" title="Editieren" @click="setedit" v-if="!edit"><Icon :path="mdiPencil" /></div>
        <div class="btn btn-edit" title="Editieren" @click="save" v-if="edit">Speichern</div>
        <div class="btn btn-delete" title="Löschen" v-if="!confirmdelete" @click="del"><Icon :path="mdiDelete" /></div>
        <div class="btn btn-delete" title="Löschen" v-if="confirmdelete" @click="del">Wirklich löschen?</div>
      </div>
      <div class="col-lg-9 justify-content-center align-self-center" v-if="!edit">
        <div class="title">{{ item.text }}</div>
        <div class="date">{{ date }}</div>
      </div>
      <div class="col-lg-9 justify-content-center align-self-center" v-if="edit">
        <div class="form-group"><span class="label-input">Titel </span> <input type="text" v-model="title" /></div>
        <div class="form-group"><span class="label-input">Link / URL</span> <input type="text" v-model="link" /></div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" :value="true" v-model="newwindow" />
          <label class="form-check-label" for="flexRadioDefault1">
            Link wird in einem "neuen" Fenster geöffnet
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" :value="false" v-model="newwindow" />
          <label class="form-check-label" for="flexRadioDefault2">
            Link wird im "selben" Fenster geöffnet
          </label>
        </div>
      </div>
      <div class="col-lg-1 justify-content-end align-self-center">
        <div class="link">
          <a :href="item.link" target="_blank">
            <Icon :path="mdiArrowRight" />
          </a>
        </div>
      </div>
      <div class="col-lg-1 justify-content-center align-self-center">
        <div class="btn-move" title="Zeile verschieben"><span>Sortierung</span><Icon :path="mdiDragVariant" /></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { mdiPencil, mdiDelete, mdiArrowRight, mdiDragVariant } from '@mdi/js';
import { formatDate } from '@/i18n';
import { useStore } from 'vuex';

type Newsflash = {
  id: number;
  text: string;
  link: string;
  order: number;
  createdat: Date;
  newwindow: boolean;
};

export default defineComponent({
  props: {
    item: { type: Object as PropType<Newsflash>, required: true },
  },
  emits: ['deleted'],
  setup(props, { emit }) {
    const store = useStore();
    const confirmdelete = ref(false);
    const edit = ref(false);
    let timeout: number;
    const title = ref();
    const link = ref();
    const newwindow = ref(true);

    const del = () => {
      if (!confirmdelete.value) {
        confirmdelete.value = true;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          confirmdelete.value = false;
        }, 3000);
      } else {
        store.dispatch('deleteNewsflash', props.item.id).then(() => {
          emit('deleted');
          store.dispatch('addNotification', { message: 'Item gelöscht' });
        });
      }
    };

    const save = () => {
      let error = false;

      if (!title.value || title.value == '') {
        error = true;
        store.dispatch('addNotification', { message: 'Bitte geben sie einen Text ein', type: 'error' });
      }

      if (!link.value || link.value == '') {
        error = true;
        store.dispatch('addNotification', { message: 'Bitte geben sie einen Link ein', type: 'error' });
      } else {
        if (!link.value.startsWith('http')) {
          link.value = `https://${link.value}`;
        }
      }

      if (!error) {
        store.dispatch('updateNewsflash', { id: props.item.id, text: title.value, link: link.value, newwindow: newwindow.value }).then(() => {
          emit('deleted');
          edit.value = false;
          store.dispatch('addNotification', { message: 'Item gespeichert.' });
        });
      }
    };

    const setedit = () => {
      edit.value = true;
      title.value = props.item.text;
      link.value = props.item.link;
      newwindow.value = props.item.newwindow;
    };

    return {
      confirmdelete,
      del,
      save,
      edit,
      setedit,
      title,
      link,
      newwindow,
      date: formatDate(props.item.createdat, 'DD.MM.YYYY HH:MM'),
      mdiPencil,
      mdiDelete,
      mdiArrowRight,
      mdiDragVariant,
    };
  },
});
</script>
