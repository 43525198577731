
import { defineComponent, PropType, ref } from 'vue';
import { mdiPencil, mdiDelete, mdiArrowRight, mdiDragVariant } from '@mdi/js';
import { formatDate } from '@/i18n';
import { useStore } from 'vuex';

type Newsflash = {
  id: number;
  text: string;
  link: string;
  order: number;
  createdat: Date;
  newwindow: boolean;
};

export default defineComponent({
  props: {
    item: { type: Object as PropType<Newsflash>, required: true },
  },
  emits: ['deleted'],
  setup(props, { emit }) {
    const store = useStore();
    const confirmdelete = ref(false);
    const edit = ref(false);
    let timeout: number;
    const title = ref();
    const link = ref();
    const newwindow = ref(true);

    const del = () => {
      if (!confirmdelete.value) {
        confirmdelete.value = true;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          confirmdelete.value = false;
        }, 3000);
      } else {
        store.dispatch('deleteNewsflash', props.item.id).then(() => {
          emit('deleted');
          store.dispatch('addNotification', { message: 'Item gelöscht' });
        });
      }
    };

    const save = () => {
      let error = false;

      if (!title.value || title.value == '') {
        error = true;
        store.dispatch('addNotification', { message: 'Bitte geben sie einen Text ein', type: 'error' });
      }

      if (!link.value || link.value == '') {
        error = true;
        store.dispatch('addNotification', { message: 'Bitte geben sie einen Link ein', type: 'error' });
      } else {
        if (!link.value.startsWith('http')) {
          link.value = `https://${link.value}`;
        }
      }

      if (!error) {
        store.dispatch('updateNewsflash', { id: props.item.id, text: title.value, link: link.value, newwindow: newwindow.value }).then(() => {
          emit('deleted');
          edit.value = false;
          store.dispatch('addNotification', { message: 'Item gespeichert.' });
        });
      }
    };

    const setedit = () => {
      edit.value = true;
      title.value = props.item.text;
      link.value = props.item.link;
      newwindow.value = props.item.newwindow;
    };

    return {
      confirmdelete,
      del,
      save,
      edit,
      setedit,
      title,
      link,
      newwindow,
      date: formatDate(props.item.createdat, 'DD.MM.YYYY HH:MM'),
      mdiPencil,
      mdiDelete,
      mdiArrowRight,
      mdiDragVariant,
    };
  },
});
