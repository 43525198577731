<style lang="scss">
#backend {
  .newsflash-all {
    .newsflash-form {
      margin-bottom: 50px;
    }
  }
}
</style>

<template>
  <div class="container-fluid newsflash-all">
    <div class="headline-h1">
      <h1>Eintrag hinzufügen</h1>
      <div class="line"><span>Newsflash / Startseite</span></div>
    </div>
    <div class="newsflash-form">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group"><span class="label-input">Titel </span> <input type="text" v-model="entry.text" /></div>
        </div>
        <div class="col-lg-6">
          <div class="form-group"><span class="label-input">Link / URL</span> <input type="text" v-model="entry.link" /></div>
        </div>
        <div class="col-lg-12">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" :value="true" v-model="entry.newwindow" />
            <label class="form-check-label" for="flexRadioDefault1">
              Link wird in einem "neuen" Fenster geöffnet
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" :value="false" v-model="entry.newwindow" />
            <label class="form-check-label" for="flexRadioDefault2">
              Link wird im "selben" Fenster geöffnet
            </label>
          </div>
        </div>
      </div>
      <div class="row justify-content-start align-self-center">
        <div class="col-lg-4 col-md-6">
          <button class="btn-std btn-color-save" @click="save">Speichern</button>
        </div>
      </div>
    </div>
    <div class="headline-h1">
      <h1>Übersicht</h1>
      <div class="line"><span>Newsflash / Startseite</span></div>
    </div>
    <div class="newsflash-overview">
      <draggable v-model="newsflashes" item-key="id" handle=".btn-move" tag="transition-group" :component-data="{ name: 'flip-list', type: 'transition' }" @change="saveOrder">
        <template #item="{element}">
          <NewsflashEntry :item="element" :key="element.id" @deleted="init" />
        </template>
      </draggable>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import NewsflashEntry from '@/components/Backend/NewsflashEntry.vue';
import draggable from 'vuedraggable';
type Newsflash = {
  id: number;
  text: string;
  link: string;
  newwindow: boolean;
  order?: number;
  createdat?: string;
};

export default defineComponent({
  components: {
    NewsflashEntry,
    draggable,
  },
  setup() {
    const store = useStore();
    const entry = ref<Newsflash>({
      id: 0,
      text: '',
      link: '',
      newwindow: true,
    });

    const newsflashes = ref<Array<Newsflash>>([]);

    const init = () => {
      entry.value = {
        id: 0,
        text: '',
        link: '',
        newwindow: true,
      };
      store.dispatch('fetchNewsflash').then((res: Array<Newsflash>) => {
        newsflashes.value = res;
      });
    };

    onMounted(init);

    const save = () => {
      let error = false;

      if (!entry.value.text || entry.value.text == '') {
        error = true;
        store.dispatch('addNotification', { message: 'Bitte geben sie einen Text ein', type: 'error' });
      }

      if (!entry.value.link || entry.value.link == '') {
        error = true;
        store.dispatch('addNotification', { message: 'Bitte geben sie einen Link ein', type: 'error' });
      } else {
        if (!entry.value.link.startsWith('http')) {
          entry.value.link = `https://${entry.value.link}`;
        }
      }

      if (!error) {
        store.dispatch('insertNewsflash', entry.value).then(() => {
          init();
          store.dispatch('addNotification', { message: 'Item gespeichert.' });
        });
      }
    };

    const saveOrder = () => {
      const order: Array<{ id: number; order: number }> = [];

      for (let i = 0; newsflashes.value.length > i; i++) {
        order.push({
          id: newsflashes.value[i].id,
          order: i,
        });
      }

      store.dispatch('updateNewsflashOrder', order);
    };

    return {
      newsflashes,
      save,
      saveOrder,
      init,
      entry,
    };
  },
});
</script>
