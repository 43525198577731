
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import NewsflashEntry from '@/components/Backend/NewsflashEntry.vue';
import draggable from 'vuedraggable';
type Newsflash = {
  id: number;
  text: string;
  link: string;
  newwindow: boolean;
  order?: number;
  createdat?: string;
};

export default defineComponent({
  components: {
    NewsflashEntry,
    draggable,
  },
  setup() {
    const store = useStore();
    const entry = ref<Newsflash>({
      id: 0,
      text: '',
      link: '',
      newwindow: true,
    });

    const newsflashes = ref<Array<Newsflash>>([]);

    const init = () => {
      entry.value = {
        id: 0,
        text: '',
        link: '',
        newwindow: true,
      };
      store.dispatch('fetchNewsflash').then((res: Array<Newsflash>) => {
        newsflashes.value = res;
      });
    };

    onMounted(init);

    const save = () => {
      let error = false;

      if (!entry.value.text || entry.value.text == '') {
        error = true;
        store.dispatch('addNotification', { message: 'Bitte geben sie einen Text ein', type: 'error' });
      }

      if (!entry.value.link || entry.value.link == '') {
        error = true;
        store.dispatch('addNotification', { message: 'Bitte geben sie einen Link ein', type: 'error' });
      } else {
        if (!entry.value.link.startsWith('http')) {
          entry.value.link = `https://${entry.value.link}`;
        }
      }

      if (!error) {
        store.dispatch('insertNewsflash', entry.value).then(() => {
          init();
          store.dispatch('addNotification', { message: 'Item gespeichert.' });
        });
      }
    };

    const saveOrder = () => {
      const order: Array<{ id: number; order: number }> = [];

      for (let i = 0; newsflashes.value.length > i; i++) {
        order.push({
          id: newsflashes.value[i].id,
          order: i,
        });
      }

      store.dispatch('updateNewsflashOrder', order);
    };

    return {
      newsflashes,
      save,
      saveOrder,
      init,
      entry,
    };
  },
});
